<template>
  <!-- eslint-disable -->
  <div class="transfer-main">
    <div class="message-box">
      <div v-for="(id,i) in messageIds" :key="i" class="message-item"
        :style="{ alignSelf: messageItems[id] ?
          (messageItems[id].sender == store.state.username ? 'flex-end' : 'flex-start') :
          (i % 2 == 0 ? 'flex-start' : 'flex-end') }">
        <div v-if="!messageItems[id]">...</div>
        <div v-if="messageItems[id]" :style="{ textAlign: store.state.username == messageItems[id].sender ? 'right' : 'left' }">{{ formatTime(messageItems[id].time) }}</div>
        <div v-if="messageItems[id]">{{ messageItems[id].message }}</div>
      </div>
    </div>
    <div class="message-section">
      <img width="30" height="30" src="https://img.icons8.com/pastel-glyph/64/file.png" />
      <Input v-model:value="messageText" @enter="sendMessage" />
      <img width="30" height="30" src="https://img.icons8.com/arcade/64/sent.png" />
      <div v-if="sendingMessage" class="message-loader">Sending Message . . .</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { clearStorage, padNum } from '../js/common';
import Input from '../components/Input.vue';
import axios from 'axios';

//#region Data
const store = useStore();
const router = useRouter();

// const messageFile = ref(null);
const messageText = ref('');
const sendingMessage = ref(false);

const messageIds = ref();
const messageItems = ref({});
//#endregion Data

//#region Methods
const sendMessage = async () => {
  if (messageText.value) {
    let fd = new FormData();
    fd.append('message', messageText.value)
    fd.append('file', null)
    fd.append('sender', store.state.username)
    fd.append('connection_id', localStorage.getItem('connectionId'))

    sendingMessage.value = true;
    let sendMessageResult = await axios.post('/Message/SendMessage', fd);
    sendingMessage.value = false;

    if (sendMessageResult.data) {
      messageText.value = '';
      store.state.hub.invoke('SendMessage', sendMessageResult.data.toString(), localStorage.getItem('connectionId'));
    }
  }
}
const formatTime = (time) => {
  let date = new Date(time);
  return `${padNum(date.getDate())}/${padNum(date.getMonth())}/${date.getFullYear()} ${padNum(date.getHours())}:${padNum(date.getMinutes())}:${padNum(date.getSeconds())}`
}
//#endregion Methods

onMounted(async () => {
  let connectedTo = localStorage.getItem('connectedTo');
  if (connectedTo) {
    let connExpired = new Date(localStorage.getItem('connectionExpired'));
    // Checking if the connection is already expired
    if (new Date() > connExpired) {
      clearStorage();
      router.push('/');
      return;
    }
    store.state.connectedTo = connectedTo;
    store.commit('startCountdown');

    setTimeout(() => {
      // Update that I'm online
      store.state.hub.invoke('UpdateOnlineState', store.state.username, true);
      // Check the connection of the connected person
      store.state.hub.invoke('CheckUserIsOnline', store.state.connectedTo);
      
      // When user receive message
      store.state.hub.on('ReceiveMessage', (id, con_id) => {
        if (con_id == localStorage.getItem('connectionId')) {
          messageIds.value.splice(0, 0, id);
          axios.get(`/Message/GetMessageById?id=${id}`).then(r => {
            messageItems.value[id] = r.data[0];
          })
        }
      })

      // When the user received another user is checking on their availabilty
      store.state.hub.on('CheckUserIsOnlineReceived', (name) => {
        if (store.state.username == name) {
          store.state.hub.invoke('UpdateOnlineState', store.state.username, true);
        }
      })
    }, 1000);

    let allIds = await axios.get(`/Message/GetAllMessagesId?connectionId=${localStorage.getItem('connectionId')}`);
    messageIds.value = allIds.data.map(a => a.message_id);

    messageIds.value.forEach(id => {
      axios.get(`/Message/GetMessageById?id=${id}`).then(r => {
        messageItems.value[id] = r.data[0];
      })
    });
  } else {
    router.push('/');
  }
});
</script>

<style scoped>
.transfer-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  padding: 10px;
  padding-top: 100px;
}
.message-box {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
.message-section {
  display: flex;
  align-items: center;
  position: relative;
}
.message-section > :not(:first-child) {
  margin-left: 5px;
}
.message-section > img {
  cursor: pointer;
}
.message-section > :first-child {
  filter: invert(var(--img-invert))
}
.message-item {
  border: 1px solid var(--font-color);
  padding: 5px;
  border-radius: 10px;
  max-width: 75%;
  margin-bottom: 5px;
  width: fit-content;
}
.message-item > :first-child {
  font-size: 0.7em;
}
.message-loader {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>